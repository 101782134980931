import { ProductJsonLd } from "next-seo";
import NextImage from "next/image";

import { host } from "../constants";
import book from "../images/book.png";
import books1 from "../images/books1.png";
// import chris from "../images/testimonials/chris_miaskowski.jpg";
import jakub from "../images/testimonials/jakub_wasik.jpg";
import piotrS from "../images/testimonials/piotr_skrzynski.jpg";
import tomasz from "../images/testimonials/tomasz_ganski.jpg";

const testimonials = [
  {
    name: "Tomasz Gański",
    title: "CTO @ Just Join IT",
    quote: `»TypeScript na poważnie« był dla mnie miłym przypomnieniem jak książki techniczne niegdyś wyglądały. Coraz częściej możemy spotkać się publikacjami, które są transkrypcjami kursów wideo albo zlepieniem blogpostów. Michał podszedł do tematu rzetelnie i w przejrzysty sposób przedstawił możliwości i różne smaczki TS-a. Tytuł na pewno nie dla osób dopiero zaczynających z programowaniem, a raczej dla osób, które z JS miały styczność.

Największą przeszkodą w odbiorze książki miałem ze wszystkimi anglojęzycznym tłumaczeniami na język polski. Zdarzały się działy gdzie dopiero w połowie orientowałem się, że dany wzorzec jest mi dobrze znany. Dla niektórych może to być zaleta, dla mnie Michał tutaj mógłby być mniej rzetelny 😉 

Zdecydowanie czekam na drugą edycję!`,
    avatarUrl: tomasz,
  },
  // {
  //   name: "Chris Miąskowski ",
  //   title: "CEO @ 11Sigma.com",
  //   quote: `Fusce pretium augue non lacus cursus, at hendrerit ligula ultrices.
  //           Morbi at nunc sit amet est viverra lacinia at eget nunc. Sed
  //           scelerisque velit lectus, ut accumsan lorem tincidunt eta saha.`,
  //   avatarUrl: chris,
  // },
  {
    name: "Piotr Skrzyński",
    title: "Pasjonata programowania",
    quote: `Świetna książka niezależnie od poziomu zaawansowania. Kupiłem ją na początku swojej przygody z TS i po ponad roku nauki, nadal podpieram się wiedzą w niej zawartą. Zagadnienia wytłumaczone w jasny i czytelny sposób. Bardzo dobrze zainwestowane pieniądze.`,
    avatarUrl: piotrS,
  },
  {
    name: "Jakub Wąsik",
    title: "Full Stack Developer",
    quote: `»TypeScript na poważnie« to świetna pozycja zarówno dla osób, które dopiero zaczynają swoją przygodę z TypeScriptem, jak i dla tych, którzy pracują z nim już od dawna. Przyjemnie wprowadza w świat JavaScriptu z typami, zaznajamia czytelnika z podstawowymi zagadnieniami i w przystępny sposób omawia bardziej zaawansowane koncepty zaskakujące nawet doświadczonych programistów.`,
    avatarUrl: jakub,
  },
];

export function Testimonials() {
  return (
    <>
      <ul className="grid max-w-2xl mx-auto gap-y-10">
        {testimonials.map((t) => (
          <Testimonial key={t.name} {...t} />
        ))}
      </ul>
      <ProductJsonLd
        productName="TypeScript na poważnie"
        images={[books1.src, book.src]}
        description="TypeScript na poważnie Michała Miszczyszyna to kurs oraz zaawansowane wskazówki i wzorce. Znajdziesz w niej wszystko, co potrzebne, aby poznać TS na wylot!"
        brand="Type of Web"
        manufacturerName="Type of Web"
        manufacturerLogo="https://typeofweb.com/apple-touch-icon.png"
        slogan="To kompendium wiedzy na temat TypeScripta! Znajdziesz w niej wszystko, co potrzebne, aby nauczyć się programować w TypeScripcie od podstaw, aż po zaawansowane niuanse, dobre praktyki i wzorce."
        releaseDate="2020-11-30T11:08:13Z"
        gtin13="9788395736339"
        award="Bestseller"
        reviews={testimonials.map((t) => ({
          author: t.name,
          datePublished: "2022-02-01T20:43:24.397Z",
          reviewBody: t.quote,
          reviewRating: {
            bestRating: "10",
            ratingValue: "10",
            worstRating: "1",
          },
          publisher: {
            type: "Person",
            name: t.name,
          },
        }))}
        aggregateRating={{
          bestRating: "10",
          ratingValue: "9.0",
          worstRating: "1",
          reviewCount: "33",
        }}
        offers={[
          {
            price: "84.99",
            priceCurrency: "PLN",
            priceValidUntil: "2025-01-01",
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock",
            url: host,
            seller: {
              name: "Type of Web",
            },
          },
          {
            price: "120.00",
            priceCurrency: "PLN",
            priceValidUntil: "2025-01-01",
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock",
            url: host,
            seller: {
              name: "Type of Web",
            },
          },
        ]}
      />
    </>
  );
}

interface TestimonialProps {
  readonly name: string;
  readonly title: string;
  readonly quote: string;
  readonly avatarUrl: string | StaticImageData;
}
function Testimonial({ name, title, quote, avatarUrl }: TestimonialProps) {
  return (
    <li className="bg-white text-black p-10 funny-border">
      <blockquote>
        <cite className="grid grid-cols-[1fr_4rem] mb-4">
          <div>
            <p className="text-2xl font-bold mb-0.5">{name}</p>
            <p className="text-gray-500 text-lg">{title}</p>
          </div>
          <div>
            <NextImage
              src={avatarUrl}
              className="rounded-full w-16 h-16"
              alt=""
            />
          </div>
        </cite>
        <div className="quote-text flex flex-col gap-y-4">
          {quote.split("\n\n").map((text, i) => (
            <p key={i} className="text-lg font-serif leading-normal">
              {text}
            </p>
          ))}
        </div>
      </blockquote>
    </li>
  );
}
