import NextImage from "next/image";
import { useState } from "react";

import book2 from "../images/book2.png";

import type { FormEventHandler, ReactNode } from "react";

type FormField = HTMLInputElement | HTMLTextAreaElement;

type FormState =
  | { readonly status: "idle" }
  | { readonly status: "loading" }
  | { readonly status: "success" }
  | { readonly status: "error"; readonly error?: string };

export const NewsletterForm = ({
  title,
  buttonText,
}: {
  readonly title: string;
  readonly buttonText: string;
}) => {
  const [formState, setFormState] = useState<FormState>({ status: "idle" });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    setFormState({ status: "loading" });

    const data = Object.fromEntries(
      Array.from(e.currentTarget.elements)
        .filter((el): el is FormField => !!el.getAttribute("name"))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked on line above
        .map((el) => [el.getAttribute("name")!, el.value] as const)
    );

    try {
      const res = await fetch("/api/subscribe", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      const json: { readonly error?: unknown } = await res.json();

      if (json.error) {
        setFormState({ status: "error", error: String(json.error) });
      } else {
        setFormState({ status: "success" });
      }
    } catch (error) {
      console.error(error);
      setFormState({ status: "error" });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {title && (
        <h3 className="font-bold text-3xl sm:text-5xl leading-tight text-black">
          {title}
        </h3>
      )}
      <div className="mt-8 grid grid-cols-2 gap-x-6 gap-y-6">
        <input
          className="rounded-lg funny-focus text-base font-bold px-4 py-4 placeholder-gray-500 text-black"
          type="text"
          autoComplete="name"
          placeholder="Imię"
          aria-label="Imię"
          name="name"
        />
        <input
          className="rounded-lg funny-focus text-base font-bold px-4 py-4 placeholder-gray-500 text-black"
          type="text"
          autoComplete="family-name"
          placeholder="Nazwisko"
          aria-label="Nazwisko"
          name="lastName"
        />
        <input
          className="rounded-lg col-span-2 funny-focus text-base font-bold px-4 py-4 placeholder-gray-500 text-black"
          type="email"
          placeholder="Email"
          aria-label="Email"
          aria-required="true"
          name="email"
          autoComplete="email"
          required
        />
        <button
          type="submit"
          disabled={formState.status === "loading"}
          className={`${formState.status === "error" ? "shake-shake" : ""} ${
            formState.status === "loading" ? "cursor-wait" : ""
          } h-20 col-span-2 funny-focus text-center inline-block bg-black text-white px-8 py-5 rounded-xl text-2xl font-bold hover:shadow-2xl transition-shadow`}
        >
          {formStateToString(buttonText, formState)}
        </button>
      </div>
    </form>
  );
};

export function Newsletter() {
  return (
    <div className="bg-yellow-300 -mx-8 rounded-3xl p-8 lg:p-16 grid md:grid-cols-2 items-center justify-center h-full gap-x-16 gap-y-16">
      <NewsletterForm
        buttonText="Odbierz rozdział"
        title="Pobierz rozdział za darmo!"
      />
      <div className="order-last md:order-first">
        <NextImage src={book2} width={575} height={360} alt="" />
      </div>
    </div>
  );
}

function formStateToString(
  buttonText: string,
  formState: FormState
): ReactNode {
  switch (formState.status) {
    case "idle":
      return buttonText;
    case "success":
      return (
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 stroke-green-400 inline-block align-middle -mt-1 -ml-2 mr-2 ping-ping animate-ping"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          Udało się! Sprawdź maila 😁
        </span>
      );
    case "error":
      return formState.error || `Ups! Coś poszło nie tak 🙈`;
    case "loading":
      return (
        <span className="inline-block spin-spin rounded-full bg-white shadow-sm w-8 h-8">
          ⏳
        </span>
      );
  }
}
