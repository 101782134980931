import Image from "next/image";

import { Newsletter, NewsletterForm } from "../components/Newsletter";
import { PillLink } from "../components/PillLink";
import { SiteHeader } from "../components/SiteHeader";
import { Testimonials } from "../components/Testimonials";
import { Toc } from "../components/Toc";
import { host } from "../constants";
import academicCap from "../images/academic-cap.svg";
import autor from "../images/autor.jpg";
import book from "../images/book.png";
import books1 from "../images/books1.png";
import chip from "../images/chip.svg";
import clipboardList from "../images/clipboard-list.svg";
import cog from "../images/cog.svg";
import colorSwatch from "../images/color-swatch.svg";
import desktopComputer from "../images/desktop-computer.svg";
import ebook from "../images/typescript-na-powaznie-ebook.png";

const isSellingPrinted = false;

const price = (
  <>
    <del className="decoration-red-600 decoration-2 text-gray-500">120 zł</del>{" "}
    84,99 zł
  </>
);

const NewEditionPill = () =>
  isSellingPrinted ? (
    <p className="px-4 py-1 mr-4 bg-transparent text-black uppercase font-bold border-4 border-blue-600 text-2xl inline-block rounded-full">
      Nowe wydanie
    </p>
  ) : null;

export default function Home() {
  return (
    <>
      <SiteHeader />
      <section
        id="hero"
        className="py-36 min-h-screen bg-yellow-300 grid -mb-8"
      >
        <div className="container grid md:grid-cols-2 gap-y-16 gap-x-16 items-center justify-center h-full">
          <div className="xl:-mt-16">
            <NewEditionPill />{" "}
            <p className="px-4 py-1 bg-red-600 text-white font-bold inline-block rounded-full">
              Bestseller
            </p>
            <h2 className="mt-4 text-5xl font-bold leading-snug">
              Zostań mistrzem TypeScripta zaledwie w kilka dni!
            </h2>
            <p className="text-2xl leading-normal mb-2 mt-4 font-light">
              Kurs i poradnik programowania w TypeScript.
            </p>
            <p className="text-2xl leading-normal mt-2 mb-4 font-light">
              Ponad 300 stron praktycznej wiedzy!
            </p>
            {isSellingPrinted ? (
              <PillLink variant="primary" href="">
                Kup książkę teraz za {price}
              </PillLink>
            ) : (
              <>
                <PillLink variant="primary" href="https://sklep.typeofweb.com/">
                  Kup ebooka teraz za {price}
                </PillLink>
                <p className="mt-8 text-xl -mb-4 leading-normal font-light">
                  Lub zgłoś zainteresowanie dodrukiem:
                </p>
                <NewsletterForm
                  title=""
                  buttonText="Poinformuj mnie gdy wróci do druku"
                />
              </>
            )}
          </div>
          <div className="order-last md:order-first text-center">
            <Image
              placeholder="blur"
              priority
              src={books1}
              width={576}
              height={460}
              alt=""
            />
          </div>
        </div>
      </section>
      <section
        id="o-ksiazce"
        className="min-h-screen p-section bg-white grid rounded-t-[2rem] -mb-8"
      >
        <div className="container grid lg:grid-cols-2 items-center justify-center h-full gap-x-16 gap-y-16">
          <div className="lg:-mt-10">
            <h2 className="mt-4 text-5xl font-bold leading-snug">
              TypeScript na poważnie
            </h2>
            <NewEditionPill />{" "}
            <p className="text-2xl leading-normal my-4 font-light">
              Ta książka zawiera wszystko, co potrzebne, aby w pełni korzystać z
              TypeScripta! Zebrałem w niej swoją wiedzę, wieloletnie
              doświadczenie, dobre praktyki i wzorce.
            </p>
            <ul className="mt-8 text-xl font-light leading-relaxed list-inside">
              <li className="tick">
                Czym jest <strong>TypeScript</strong>?
              </li>
              <li className="tick">
                Jak <strong>bezpiecznie</strong> budować aplikacje?
              </li>
              <li className="tick">
                Jak <strong>projektować</strong> typy?
              </li>
              <li className="tick">
                Jak opisać typami <strong>walidację</strong> w trakcie działania
                programu?
              </li>
              <li className="tick">
                Jak prawidłowo otypować <strong>dane z API</strong>?
              </li>
            </ul>
            {isSellingPrinted ? (
              <PillLink variant="inverted" href="">
                Kup książkę teraz za {price}
              </PillLink>
            ) : (
              <PillLink variant="inverted" href="https://sklep.typeofweb.com/">
                Kup ebooka teraz za {price}
              </PillLink>
            )}
          </div>
          <div className="text-center">
            <Image
              placeholder="blur"
              src={book}
              alt=""
              width={300}
              height={475}
              className="drop-shadow- 2xl"
            />
          </div>
        </div>
      </section>
      <section
        id="opinie"
        className="min-h-screen p-section bg-gray-800 text-white grid items-center grid-rows-1 rounded-t-[2rem] -mb-8"
      >
        <div className="container xl:-mt-16">
          <h2 className="mb-8 text-4xl text-center font-bold leading-snug self-end">
            Co mówią czytelnicy?
          </h2>
          <Testimonials />
        </div>
      </section>
      <section
        id="o-autorze"
        className="min-h-screen p-section bg-white grid rounded-t-[2rem] -mb-8"
      >
        <div className="container grid lg:grid-cols-2 gap-x-16 items-center justify-center h-full">
          <div className="md:-mt-10">
            <h2 className="mt-4 text-5xl font-bold leading-snug">O autorze</h2>
            <p className="text-xl leading-normal my-4 font-normal">
              Cześć! Nazywam się Michał i bardzo się cieszę, że tu jesteś!
            </p>
            <p className="text-xl leading-normal my-4 font-normal">
              Na co dzień zajmuję się programowaniem, a od czterech lat tworzę
              bloga <strong>Type of Web</strong>, który jest jednym z
              najpopularniejszych blogów programistycznych w Polsce. Poza tym
              mogliśmy się spotkać na różnych konferencjach i meetupach, które
              współorganizowałem, występowałem na nich lub byłem patronem.
            </p>
            <p className="text-xl leading-normal my-4 font-normal">
              Współpracuję przede wszystkim z zespołami w USA i buduję aplikacje
              Internetowe z wykorzystaniem różnych technologii (TypeScript,
              Golang, Scala).
            </p>
            <p className="text-3xl leading-normal my-6 font-normal italic">
              Michał Miszczyszyn
            </p>
          </div>
          <div className="order-last lg:order-first lg:-mt-10">
            <Image
              alt=""
              placeholder="blur"
              src={autor}
              className="funny-border"
            />
          </div>
        </div>
      </section>

      <section
        id="kompletny-przewodnik"
        className="min-h-screen p-section bg-blue-50 text-black grid items-center grid-rows-1 rounded-t-[2rem] -mb-8"
      >
        <div className="container xl:-mt-16">
          <h2 className="mb-8 text-4xl text-center font-bold leading-snug self-end">
            Kompletny przewodnik
          </h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-y-16 gap-x-8">
            <li>
              <Image
                src={academicCap}
                className="w-16 h-16"
                width="64"
                height="64"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">
                Szybki start z TypeScriptem
              </h3>
              <p className="text-lg">
                Przeprowadzę Cię krok po kroku przez stworzenie projektu i
                kompletną konfigurację zarówno{" "}
                <strong>w Node.js, jak i na frontendzie</strong>. Zajmie to nie
                więcej, niż 10 minut.
              </p>
            </li>

            <li>
              <Image
                src={chip}
                className="w-16 h-16"
                width="64"
                height="64"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">Zaawansowane typy</h3>
              <p className="text-lg">
                Poznasz niesamowite możliwości, jakie dają typy zaawansowane.
                Wśród nich typy warunkowe, typy mapowane, tworzenie{" "}
                <strong>algebraicznych typów danych</strong> i dużo więcej.
                Dzięki temu będziesz w stanie pisać kod, który uniemożliwia
                popełnienie błędów.
              </p>
            </li>

            <li>
              <Image
                src={clipboardList}
                className="w-16 h-16"
                width="64"
                height="64"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">
                Bezpieczna praca z danymi
              </h3>
              <p className="text-lg">
                Praca z zewnętrznymi <strong>API w TypeScripcie</strong> sprawia
                Ci problem? Nie czujesz się do końca pewnie? Pokazuję, jak
                w pełni bezpiecznie operować na danych w TS!
              </p>
            </li>

            <li>
              <Image
                src={cog}
                className="w-16 h-16"
                width="64"
                height="64"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">
                Typowanie silne i statyczne
              </h3>
              <p className="text-lg">
                Czym różnią się od siebie typy silne, statyczne, dynamiczne i
                słabe? Co to jest duck typing i 
                <strong>typowanie strukturalne</strong>? Jakie mają wady
                i zalety?
              </p>
            </li>

            <li>
              <Image
                src={colorSwatch}
                className="w-16 h-16"
                width="64"
                height="64"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">Kompatybilność typów</h3>
              <p className="text-lg">
                Co to znaczy, że typ jest kowariantny? Łagodnie wprowadzę Cię w
                świat <strong>kompatybilności typów</strong> i dokładnie
                wytłumaczę wszelkie niuanse tak, aby żadne zachowanie nie było
                dla Ciebie zaskoczeniem!
              </p>
            </li>

            <li>
              <Image
                src={desktopComputer}
                className="w-16 h-16"
                width="64"
                height="64"
                loading="lazy"
                alt=""
              />
              <h3 className="text-2xl font-bold mb-2">Typy nominalne</h3>
              <p className="text-lg">
                Czy TypeScript ma typy nominalne? Czym one są i do czego się
                mogą przydać? Jak połączyć je z <strong>walidacją</strong>? Jak
                mieć pewność, że pracujemy z właściwymi danymi?
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        id="dla-kogo"
        className="min-h-screen pt-32 pb-24 bg-white text-black grid items-center grid-rows-1 rounded-t-[2rem] -mb-8"
      >
        <div className="container m-auto">
          <h2 className="mb-8 text-4xl text-center font-bold leading-snug self-end">
            Dla kogo ta książka?
          </h2>
          <ul className="grid grid-cols-1 lg:grid-cols-3 gap-x-16">
            <li>
              <h3 className="mt-4 text-3xl font-bold leading-snug">
                Książka dla każdego
              </h3>
              <p className="text-xl leading-normal my-4 font-normal">
                Niezależnie od tego, czy dopiero <strong>zaczynasz</strong>{" "}
                swoją przygodę z programowaniem, czy masz tytuł{" "}
                <strong>seniora</strong>, niezależnie, czy tworzysz{" "}
                <strong>front-end</strong> z użyciem popularnych frameworków,
                czy może piszesz <strong>backend w node.js</strong>, na pewno
                znajdziesz tutaj coś, co zainspiruje i przekona Cię do używania{" "}
                <strong>TypeScripta</strong> na co dzień.
              </p>
            </li>
            <li>
              <h3 className="mt-4 text-3xl font-bold leading-snug">
                Tylko praktyka i doświadczenie
              </h3>
              <p className="text-xl leading-normal my-4 font-normal">
                Nie ulega wątpliwości, że <strong>statyczne typowanie</strong>{" "}
                eliminuje całą gamę błędów wynikających z niepewności odnośnie
                typów danych. W tej książce nie będziemy wałkować{" "}
                <strong>suchej teorii</strong>! Poznasz
                <strong>konkretne techniki</strong> i skorzystasz z mojego{" "}
                <strong>doświadczenia</strong> w pracy z TypeScriptem przy{" "}
                <strong>ogromnych</strong> komercyjnych projektach!
              </p>
            </li>
            <li>
              <h3 className="mt-4 text-3xl font-bold leading-snug">
                Od juniora do seniora w TS
              </h3>
              <p className="text-xl leading-normal my-4 font-normal">
                Książka zawiera wszystko, co potrzebne, aby{" "}
                <strong>zacząć od zera</strong> i szybko dojść do{" "}
                <strong>zaawansowanej znajomości TypeScripta</strong>. Nauczę
                Cię, jak pisać bezpieczne aplikacje w TypeScripcie od podstaw,
                aż po <strong>najbardziej szczegółowe detale</strong> działania
                TS, które sprawią, że Twój kod stanie się jeszcze lepszy.
              </p>
            </li>
          </ul>

          <h2 className="mb-8 mt-32 text-4xl text-center font-bold leading-snug self-end">
            Co znajdziesz w książce?
          </h2>
          <Newsletter />
        </div>
      </section>
      <section
        id="spis-tresci"
        className="pt-4 pb-40 bg-white text-black grid items-center grid-rows-1 rounded-t-[2rem] -mb-8"
      >
        <div className="container m-auto max-w-3xl flex flex-col justify-center items-center">
          <h2 className="mb-8 text-4xl text-center font-bold leading-snug">
            Spis treści
          </h2>
          <Toc />
          {isSellingPrinted ? (
            <PillLink variant="inverted" href="">
              Kup książkę teraz za {price}
            </PillLink>
          ) : (
            <PillLink variant="inverted" href="https://sklep.typeofweb.com/">
              Kup ebooka teraz za {price}
            </PillLink>
          )}
        </div>
      </section>
      <section
        id="kup-teraz"
        className="min-h-screen p-section bg-gray-800 text-white grid items-center grid-rows-1 rounded-t-[2rem] -mb-8"
      >
        <div className="container m-auto max-w-4xl">
          <h2 className="mb-8 text-4xl text-center font-bold leading-snug self-end">
            Tak! Chcę tę książkę…
          </h2>
          <p className="text-center text-xl mt-8 leading-normal">
            „TypeScript na poważnie” to pozycja, która nauczy Cię wszystkiego,
            co musisz wiedzieć, aby efektywnie pracować z TypeScriptem. Jest to{" "}
            <strong>kompendium wiedzy</strong>, od zera aż po najbardziej
            zaawansowane techniki i kruczki tego języka programowania. Wszystko
            poparte jest <strong>praktycznymi przykładami</strong> wziętymi
            z prawdziwych projektów, nad którymi pracowałem. Pierwsze wydanie
            było absolutnym bestsellerem, a cały nakład wyprzedał się w ciągu
            zaledwie <strong>kilku dni</strong>!
          </p>
          {/* <p className="text-center text-2xl font-bold my-8">
            Tylko do końca tygodnia -25% i zakładka do książki gratis!
          </p> */}
          <p className="text-center text-5xl font-bold my-8"> {price}</p>
          {/* <p className="text-center text-2xl font-bold my-8">
            Użyj kodu{" "}
            <mark className="px-2 py-1 mx-2 bg-red-600 text-yellow-300">
              TYPEOFWEBXD
            </mark>{" "}
            przy zamówieniu.
          </p> */}
          {isSellingPrinted ? (
            <p className="text-center text-2xl font-bold my-8">
              <PillLink href="" variant="inverted">
                Kupuję książkę teraz
              </PillLink>
            </p>
          ) : (
            <p className="text-center text-2xl font-bold my-8">
              <PillLink href="https://sklep.typeofweb.com/" variant="inverted">
                Kupuję ebooka teraz
              </PillLink>
            </p>
          )}
          <div className="bg-white p-8 rounded-lg mt-16">
            <Image
              src={ebook}
              alt="TypeScript na poważnie również w wersji ebook"
            />
          </div>
          <div className="bg-yellow-300 rounded-3xl p-8 lg:p-16 mt-16">
            <NewsletterForm
              title="Zgłoś zainteresowanie dodrukiem:"
              buttonText="Poinformuj mnie gdy wróci do druku"
            />
          </div>
        </div>
      </section>
      <footer className="pt-8 pb-8 bg-blue-50 text-black grid items-center grid-rows-1 rounded-t-[2rem] -mb-8">
        <div className="container m-auto max-w-4xl text-center">
          <p>
            © {new Date().getFullYear()} | Wydawnictwo Type of Web - Michał
            Miszczyszyn{" "}
            <a
              href="https://typeofweb.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={200}
                className="mx-auto my-2"
                viewBox="0 0 632 106"
              >
                <g fill="none">
                  <path
                    fill="#161326"
                    d="M.5 12.7 17 10v17.2h19.8v13.7H17v20.5c0 3.5.6 6.3 1.8 8.3 1.2 2.1 3.7 3.2 7.4 3.2 3.6 0 7.2-.7 10.6-2l2.3 13A38 38 0 0 1 12.3 85 17.5 17.5 0 0 1 1.6 71.9C.8 68.6.5 65 .5 61.7v-49Zm102.6 14.5a510.1 510.1 0 0 1-22.4 60.2c-1.4 3-3 5.7-4.7 8.5a27 27 0 0 1-5 5.7 18 18 0 0 1-6.4 3.3 33.3 33.3 0 0 1-20.7-1.3l3-13.1c2.8 1 5.7 1.5 8.7 1.5 3 0 5.2-.8 6.8-2.4a19 19 0 0 0 4-6.6c-9-17.9-16.3-36.6-21.9-55.8H62l2.6 9.3a443 443 0 0 0 9.9 29.1c2.3-6.2 4.3-12.5 6-18.9l5.4-19.5h17.2Zm46 29.6a20 20 0 0 0-3.4-12.3c-2.3-3-5.7-4.6-10.2-4.6a46 46 0 0 0-7.2.6v29.8c1.2.8 2.6 1.3 4 1.7 1.7.4 3.4.6 5 .6 7.9 0 11.8-5.3 11.8-15.8Zm16.8-.5a43 43 0 0 1-1.7 12.3c-1 3.5-2.6 6.7-4.8 9.6a22 22 0 0 1-8 6.2c-7.4 3-15.8 3-23.1-.3V106h-16.5V29.2a65 65 0 0 1 11.1-2.4c4.2-.6 8.5-.9 12.7-1 4.8 0 9 .8 12.8 2.2 7.3 2.7 13 8.4 15.5 15.7 1.3 3.8 2 8 2 12.6Zm48.7-5.8c-.1-1.4-.3-2.8-.7-4.1-.4-1.3-1-2.5-2-3.6-.8-1-2-2-3.1-2.5-1.5-.8-3.2-1-4.9-1-3-.2-6 1-8 3.4a8 8 0 0 0-2 3.6c-.5 1.3-.9 2.7-1.1 4.2h21.8Zm-38.7 6.3c0-5.2.7-9.7 2.3-13.6a27.8 27.8 0 0 1 25.6-17.6c8.2 0 14.7 2.6 19.5 7.6 4.8 5 7.2 12.5 7.2 22.4l-.1 3.1-.2 3h-37.4a11 11 0 0 0 4.7 8.3c2.8 2 6.6 3 11.3 3 5.5 0 10.9-1 16-3l2.3 13.5a62.8 62.8 0 0 1-20.5 3.6c-5.2 0-9.8-.7-13.7-2.3a25 25 0 0 1-15.3-16 38 38 0 0 1-1.8-12h.1Z"
                  />
                  <path
                    fill="#5AB783"
                    d="m269.7 83.5-28.2-22.2v-5.5l28.2-22 2.5 6.6-22.4 18.2 22.4 18.1-2.5 6.8Zm56.8-26.6c0-6.5-1.5-11.6-4.4-15.4a14.1 14.1 0 0 0-11.8-5.7c-5 0-9 2-11.8 5.7-3 3.8-4.4 9-4.4 15.4 0 6.5 1.5 11.6 4.4 15.4a14 14 0 0 0 11.8 5.7c5 0 9-2 11.8-5.7 3-3.8 4.4-9 4.4-15.4Zm10.7 0c0 4.2-.7 8.4-2 12.3a28.2 28.2 0 0 1-5.6 9.5 24.5 24.5 0 0 1-8.5 6.1c-6.9 3-14.7 3-21.6.1a24.4 24.4 0 0 1-8.5-6.2c-2.5-2.8-4.4-6-5.6-9.5-2.6-8-2.6-16.6 0-24.6a28 28 0 0 1 5.6-9.5 25 25 0 0 1 19.3-8.3c3.9 0 7.5.7 10.8 2.1a27.2 27.2 0 0 1 14.1 15.7c1.3 4 2 8.1 2 12.3Zm31-56.9c4.2 0 8.3.6 12.3 1.9l-1.9 8.8c-1.2-.6-2.4-1-3.7-1.3-1.6-.4-3.6-.6-6-.6-4.7 0-8 1.3-10 4-2 3.1-3 6.8-2.8 10.5v5h22v8.5h-22v48.8h-10.3V23c0-7.4 1.8-13 5.4-17 3.6-4 9.3-6 17-6Zm50.3 61.4-28.2 22-2.5-6.5 22.4-18.3-11.2-9c-3.7-3.2-7.4-6.2-11.2-9.2l2.5-6.7 28.2 22.1v5.6Z"
                  />
                  <path
                    fill="#161326"
                    d="M465.5 52.2c-3 11.2-6.2 22.3-9.9 33.3h-12.8a483 483 0 0 1-14.2-41c-1.8-5.6-3.5-11.3-5.1-17h17.4a1785 1785 0 0 0 9.2 37.9 450 450 0 0 0 5.5-19.9l2.4-9.4 2-8.6h12l2 8.6 2.3 9.4c1.7 6.7 3.4 13.4 5.3 20a433 433 0 0 0 7.4-29.3l2-8.7h17a875.7 875.7 0 0 1-15 47 216 216 0 0 1-4.3 11h-12.8l-5.5-16.7a329 329 0 0 1-4.8-16.6h-.1Zm86-1.5c-.1-1.4-.4-2.8-.8-4.1a10 10 0 0 0-5-6 9.4 9.4 0 0 0-4.9-1 9.8 9.8 0 0 0-10.1 7 18 18 0 0 0-1 4h21.7l.1.1Zm-38.8 6.2c0-5.1.8-9.6 2.4-13.5a29 29 0 0 1 6.3-9.6 28 28 0 0 1 19.1-7.8c8.3 0 14.8 2.5 19.6 7.5 4.8 5 7.2 12.5 7.2 22.2l-.1 3.2-.3 3h-37.3c.3 3.2 2 6.2 4.7 8 2.8 2 6.6 3 11.3 3a48 48 0 0 0 16-2.9l2.2 13.4a60.8 60.8 0 0 1-20.4 3.6c-5.3 0-9.8-.8-13.7-2.3a25 25 0 0 1-15.2-15.9 37.6 37.6 0 0 1-1.8-11.9Zm102-.8c0-10.6-4-16-11.7-16-3.2 0-6.3.9-9.1 2.4v29.9c2.4.4 4.8.6 7.2.5 4.5 0 7.9-1.5 10.2-4.6a20 20 0 0 0 3.4-12.2Zm16.8.4c0 4.6-.7 8.8-2 12.5a26 26 0 0 1-15.4 15.7 36 36 0 0 1-12.9 2.2 83 83 0 0 1-23.7-3.3v-81L593.8 0v28.9a29.5 29.5 0 0 1 23.1-.4c3.2 1.4 5.8 3.4 8 6 2.2 2.9 3.9 6.1 4.8 9.6 1.2 4 1.7 8.2 1.7 12.4h.1Z"
                  />
                </g>
              </svg>
            </a>
          </p>
          <p className="my-2">
            <a
              className="underline underline-offset-1"
              href={`${host}/regulamin/`}
            >
              Regulamin
            </a>{" "}
            <a
              className="underline underline-offset-1"
              href={`${host}/polityka-prywatnosci/`}
            >
              Polityka prywatności
            </a>
          </p>
          <ul className="flex justify-center flex-row gap-x-3">
            <li>
              <a
                href="https://facebook.com/typeofweb"
                className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
              >
                <svg aria-label="facebook" className="icon icon-facebook2">
                  <use xlinkHref="#icon-facebook2"></use>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/c/typeofweb"
                className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
              >
                <svg aria-label="youtube" className="icon icon-youtube">
                  <use xlinkHref="#icon-youtube"></use>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/michal_typeofweb"
                className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
              >
                <svg aria-label="instagram" className="icon icon-instagram">
                  <use xlinkHref="#icon-instagram"></use>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/typeofweb"
                className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
              >
                <svg aria-label="twitter" className="icon icon-twitter">
                  <use xlinkHref="#icon-twitter"></use>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com/in/mmiszczyszyn"
                className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
              >
                <svg aria-label="linkedin" className="icon icon-linkedin">
                  <use xlinkHref="#icon-linkedin"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
