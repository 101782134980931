import type { ReactNode } from "react";

const variants = {
  primary: `bg-black text-white`,
  inverted: `bg-yellow-300 text-black`,
} as const;

interface PillLinkProps {
  readonly variant: keyof typeof variants;
  readonly children: ReactNode;
  readonly href: string;
}

export function PillLink({ variant, children, href }: PillLinkProps) {
  return (
    <a
      href={href}
      className={`${variants[variant]} funny-focus text-center md:text-left inline-block mt-6 px-8 py-5 rounded-xl text-2xl font-bold hover:shadow-2xl transition-shadow`}
    >
      {children}
    </a>
  );
}
