export function Toc() {
  return (
    <ol className="ol text-xl md:columns-2 gap-x-16 mb-8">
      <li>
        <span className="font-bold" aria-hidden>
          1.
        </span>{" "}
        Wstęp: Po co TypeScript?
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          2.
        </span>{" "}
        TypeScript w 10 minut
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          3.
        </span>{" "}
        Dygresja: Wprowadzenie do przydatnych elementów z ES6+
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          4.
        </span>{" "}
        Typowanie statyczne i typowanie silne
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          5.
        </span>{" "}
        Typy elementarne
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          6.
        </span>{" "}
        Funkcje
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          7.
        </span>{" "}
        Klasy i interfejsy
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          8.
        </span>{" "}
        Typy generyczne
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          9.
        </span>{" "}
        Inferencja typów i const
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          10.
        </span>{" "}
        Kompatybilność typów
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          11.
        </span>{" "}
        Enumy
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          12.
        </span>{" "}
        Typy zaawansowane
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          13.
        </span>{" "}
        Typy warunkowe
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          14.
        </span>{" "}
        Unie w praktyce
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          15.
        </span>{" "}
        Bezpieczna praca z danymi
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          16.
        </span>{" "}
        Typy nominalne
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          17.
        </span>{" "}
        Własna walidacja, typy warunkowe i testowanie typów
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          18.
        </span>{" "}
        Implementacja systemu jednostek w TS
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          19.
        </span>{" "}
        Migracja z JavaScriptu
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          20.
        </span>{" "}
        Pliki definicji typów .d.ts
      </li>
      <li>
        <span className="font-bold" aria-hidden>
          21.
        </span>{" "}
        Popularne problemy z TS
      </li>
      <li>
        <span className="font-bold">Dodatek:</span> Przydatne typy
      </li>
    </ol>
  );
}
